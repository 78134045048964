import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import "./newsletter.css"


// const style = (theme) => ({
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 300,
//     [theme.breakpoints.up('md')]: {
//         width: 400,
//     },
//     bgcolor: 'background.paper',
//     // border: '0px solid #000',
//     borderRadius: '5px',
//     boxShadow: 24,
//     p: 1,
// });

const text = '<pre style="white-space: pre-line">🏨 海福商旅住宿優惠！\n🔵 透過電話或官網預訂限量套裝住宿 + 租車活動者即贈⛽️ 加油金！\n讓您遊玩金門省荷包 🤙🏻 \n\n\
🛵 A方案$3180\n豪華雙人房一晚 + 機車一日\n（此方案需租借兩日方能參加活動）\n✔️贈送 ⛽️ 加油金100元\n\n 🚗 B方案$4280\n\
豪華雙人房一晚 + 五人座轎車一日\n✔️贈送 ⛽️ 加油金100元\n\n 🚐 C方案$5180\n豪華雙人房一晚 + 九人座商務車一日\n✔️贈送 ⛽️ 加油金200元\n\n\
以上皆包含✈️機場 - 🏨 飯店來回接送。\n\n🛵 機車年份為2021年式\n🚗 汽車年份為2022年式\n\n<span style="color: red;">若人數為雙人以上，歡迎來電洽詢。</span>\n海福商務飯店: 082-322538\n海福花園飯店: 082-353888\n\n‼️以上優惠為限量供應</pre>';

const env_policy = '<pre style="white-space: pre-line">邀請您一起環保愛地球！🌍\n永續旅遊-客房備品指南\n\n\
📣2025年1月1日起飯店不提供一次性備品（牙刷、牙膏、刮鬍刀、梳子、棉花棒及浴帽等）邀請旅人自備個人盥洗用具，\n感謝您的配合與我們一同實踐永續旅遊。</pre>'

export default function Newsletter(props) {
    const [open, setOpen] = useState(true);
    // const [popups, setPopups] = useState(false);
    const [popups, setPopups] = useState([]);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        // TODO: fetch popup from backend for landing or hotel page
        if (props.is_hotel_page) {
            setOpen(true)
            setPopups([{ Content: env_policy, Image: './hotel_popup.webp' }])
        } else {
            fetch(process.env.REACT_APP_API_URL + "/popup")
                .then(response => response.json())
                // 4. Setting *dogImage* to the image url that we received from the response above
                .then(result => {
                    if (!result) {
                        setOpen(false)
                    }
                    setPopups(result)
                })
                .catch(e => {
                    console.log(e);
                    this.setState({ ...this.state })
                })
        }
    }, []);

    const theme = useTheme();
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: '70vw',
                    maxHeight: '70vh',
                    [theme.breakpoints.down('md')]: {
                        width: '70vw',
                        height: '80vh',
                        p: 3,
                    },
                    bgcolor: 'background.paper',
                    // border: '0px solid #000',
                    borderRadius: '5px',
                    boxShadow: 24,
                    p: 5,
                    overflowY: 'scroll'
                }}>
                    {
                        popups?.map(popup => <div key={`popup-${popup.Id}`}>
                            <div dangerouslySetInnerHTML={{ __html: popup.Content }} />
                            {popup.Image && <img className='image' src={popup.Image} />}
                        </div>)
                    }
                    {/* {popups[0] && popups[0].Image && <img className='image' src={popups[0].Image} />}
                    <div dangerouslySetInnerHTML={{ __html: popups[0].Content }} /> */}
                </Box>
            </Modal>
        </div>
    );
}